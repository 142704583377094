import { Card } from "react-bootstrap";

export const PaymentNote = () => {
  return (
    <Card
      className="shadow-sm"
      style={{
        borderTop: "0",
        borderLeft: "3px solid green",
        borderBottom: "0",
        borderRight: "0",
        borderRadius: "6px",
      }}
    >
      <Card.Body className="text-muted" style={{ fontSize: "14px" }}>
        <p>-預刷卡仍未扣款,也尚未完成預訂;待收禮者確定商品後,才扣款完成預訂.</p>
        <p>-收禮者確定商品前,送禮人可以隨時取消預訂.</p>
        <p>-完成預訂後,我方保留最後交易決定權.</p>
        <strong className="text-danger">-本平台不支援外島配送.</strong>
      </Card.Body>
    </Card>
  );
};
