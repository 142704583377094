import {
  APITransferReq,
  APITransferInfo,
  APITransferCheck,
  APITransferChangeGift,
  APIFetchGiftById,
} from "API/order";
import { APIChangeGiftRecommendProduct } from "API/recommend";
import { Http_request_get, Http_request_post } from "Service/HttpService";

export const requestGiftUrl = async (giftInfo) => {
  let result = await Http_request_post(APITransferReq, { giftInfo });

  if (!result.data) throw new Error(result.message);

  let q = new URLSearchParams({ e: result.data, openExternalBrowser: 1 });

  let url = `${window.origin}/giftRes?${q.toString()}`;

  return url;
};

export const fetchGiftInfo = async (giftToken) => {
  let result = await Http_request_post(APITransferInfo, { giftToken });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchChangeGiftRecommend = async (exclude) => {
  let q = new URLSearchParams({ exclude }).toString();

  let result = await Http_request_get(`${APIChangeGiftRecommendProduct}?${q}`);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchGiftAnonymous = async (id) => {
  let result = await Http_request_get(APIFetchGiftById + `/${id}`);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const check = async (giftToken, giftParam, paymentInfo) => {
  let result = await Http_request_post(APITransferCheck, {
    giftToken,
    giftParam,
    paymentInfo,
  });

  if (!result.data) throw new Error(result.message);
  return result.data;
};

export const changeGift = async (updateData) => {
  let result = await Http_request_post(APITransferChangeGift, updateData);
  if (!result.data) throw new Error(result.message);
  return result.data;
};
