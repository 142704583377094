export const APIFetchOwnOrder = `/order/product/user`;
export const APIFetchOrderById = `/order/product/user/id`;
export const APICheckOrder = `/order/product/user/check`;
// export const APITransferInfo = `/order/transfer/info`;
// export const APITransferReq = `/order/transfer/request`;
export const APITransferRes = `/order/transfer/response`;
// export const APITransferCheck = `/order/transfer/check`;
// export const APITransferChangeGift = `/order/transfer/changeGift`;

export const APIFetchOwnScrapeOrder = `/order/product/user/scrape`;
export const APIFetchScrapeOrderById = `/order/product/user/scrape`;
export const APIRefundScrape = `/order/product/user/scrape/refund`
export const APITransferInfo = `/order/transfer/scrape/q`;
export const APITransferReq = `/order/transfer/scrape/create`;
export const APITransferCheck = `/order/transfer/scrape/checked`;
export const APITransferChangeGift = `/order/transfer/scrape/change`;
export const APIMarkupCb = `/order/transfer/scrape/markUpCb`;
export const APIFetchGiftById = `/order/transfer/scrape/q`;

export const APICheck = `/order/product/user`;
export const APICheckCash = `/order/product/user/cash`;
export const APIRefund = `/order/product/user`;
export const APIRefundCash = `/order/product/user/cash`;
export const APIGradeReview = `/order/review`;
export const APIFetchReview = `/order/review`;

export const APIEcpayReq = `/order/product/user`;
export const APISOEcpayReq = `/order/product/user/scrape`;
export const APISOCal = `/order/transfer/scrape/pre-cal`;


export const APIValidPhoneBarCode = `/order/ecpay/valid/phoneBarCode`