import React, { useState, useMemo } from "react";
import "./OverflowText.css";

export const OverflowText = ({
  text = "",
  max = "25",
  size = "16px",
  maxLine = 3,
}) => {
  const [readMore, setReadMore] = useState(false);
  const line = useMemo(() => text.split("\n").length, [text]);
  const char = useMemo(() => text.length, [text]);
  const isOverflow = useMemo(() => {
    if (line > maxLine) return true;
  }, [line, char]);

  return (
    <p
      className="text-muted"
      style={{ fontSize: size, whiteSpace: "pre-line" }}
      onClick={(e) => {
        // e.stopPropagation();
        setReadMore(!readMore);
      }}
    >
      {readMore || text.length <= max ? text : text.substring(0, max) + "..."}
      <br />
      {text.length > max && (
        <small className="text-primary">
          {readMore ? "隱藏內容" : "了解更多"}
        </small>
      )}
    </p>
  );
};

export const OverflowE = ({ text = "", fontSize = 18, width = "auto" }) => {
  return (
    <p className="over-ellipsis" style={{ fontSize, width }}>
      {text}
    </p>
  );
};
