import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Button, Table, Spinner, Modal, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import { fetchMarkup, fetchPayment, markupCallback } from "./PaymentUtils";
import {
  OrderCardWithOption,
  OrderRecord,
  OrderShortCard,
} from "Components/Order/ScrapeOrder";
import { useUser } from "Provider/UserProvider";

export const PaymentResultModal = ({ paymentID }) => {
  const [show, setShow] = useState(false);
  const [record, setRecord] = useState();
  const [isPending, setIsPending] = useState(false);
  const history = useHistory();
  const isSuccess = useMemo(() => {
    if (!record?._id) return;
    return record.status === process.env["REACT_APP_COUPON_STATUS_CATCH"];
  }, [record]);

  useEffect(() => {
    if (!paymentID) return;

    setShow(true);

    setIsPending(true);

    fetchPayment(paymentID)
      .then((res) => setRecord(res))
      .catch((err) => setRecord({ err: err.message }))
      .finally(() => setIsPending(false));
  }, [paymentID]);

  const handleOnHide = () => {
    history.push("/");
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Body>
        {isPending && (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        )}
        {record?.err && (
          <Row className="justify-content-center text-muted">
            <h4>{record.err}</h4>
          </Row>
        )}
        {record?._id && (
          <Row className="justify-content-center" xs={1} lg={1}>
            <Col className="text-center">
              {isSuccess ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="4x"
                  style={{ color: "#28a745" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="4x"
                  style={{ color: "#dc3545" }}
                />
              )}

              <h5 className="text-success">
                {isSuccess ? "付款成功" : "付款失敗"}
              </h5>
            </Col>
            <Col>
              <Table size="sm" responsive borderless striped>
                <tbody>
                  <tr>
                    <td>交易紀錄編號</td>
                    <td>{record.tradeNo}</td>
                  </tr>
                  <tr>
                    <td>店家名稱</td>
                    <td>{record?.sellerData?.name}</td>
                  </tr>
                  <tr>
                    <td>商品名稱</td>
                    <td>
                      {record.products && record?.products[0]?.productName}
                    </td>
                  </tr>
                  <tr>
                    <td>金額</td>
                    <td>{record.totalPrice}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnHide}>確定</Button>
      </Modal.Footer>
    </Modal>
  );
};

export const PaymentResultPage = () => {
  const { paymentID } = useParams();
  const [record, setRecord] = useState({});
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (!paymentID) return;

    setIsPending(true);

    fetchPayment(paymentID)
      .then((res) => setRecord(res))
      .catch((err) => setRecord({ err: err.message }))
      .finally(() => setIsPending(false));
  }, [paymentID]);

  const isSuccess = useMemo(() => {
    if (!record?._id) return;
    return record.status === process.env["REACT_APP_COUPON_STATUS_CATCH"];
  }, [record]);

  if (isPending) {
    return (
      <Row className="justify-content-center">
        <Col xs="auto">
          <Spinner animation="border" />
        </Col>
      </Row>
    );
  }

  if (!record?._id) {
    return (
      <div className="text-center">
        <h4>
          <strong>找不到訂單</strong>
        </h4>
      </div>
    );
  }

  return (
    <div>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={10}>
          <Card
            style={{
              background: "linear-gradient(90deg, #1CB5E0 0%, #000851 100%)",
            }}
          >
            <Card.Body>
              <section className="status-sec text-center">
                <span>
                  {isSuccess ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="3x"
                      style={{ color: "#fff" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="3x"
                      style={{ color: "#dc3545" }}
                    />
                  )}
                </span>
                <h5 className="text-white py-2">
                  {isSuccess ? "付款成功" : "付款失敗"}
                </h5>
              </section>
            </Card.Body>
          </Card>
          <br />
          <OrderShortCard order={record} />

          <br />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button as={Link} to={"/"}>
                回首頁
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                as={Link}
                to={`/user/order?id=${record._id}`}
                variant="outline-primary"
              >
                查看訂單
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export const GiftMarkUpPaymentResult = () => {
  const { paymentID } = useParams();
  const { user } = useUser();
  const [record, setRecord] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);

    const fetchOrder = async () => {
      try {
        let order = await fetchMarkup(paymentID);

        if (order.status === process.env["REACT_APP_COUPON_STATUS_CATCH"]) {
          order = await markupCallback(order._id);
        }

        setRecord(order);
      } catch (err) {
        setErrMsg(err.message);
      } finally {
        setPending(false);
      }
    };
    fetchOrder();
  }, []);

  const isSuccess = useMemo(() => {
    if (!record?._id) return;
    return (
      record.status === process.env["REACT_APP_COUPON_STATUS_CATCH"] ||
      record.status === process.env["REACT_APP_COUPON_STATUS_CHECK"]
    );
  }, [record]);

  if (pending) return <Spinner animation="border" />;

  if (!record?._id) return <h4 className="text-center">找不到訂單</h4>;

  return (
    <div>
      {errMsg && <h4 className="text-center">{errMsg}</h4>}

      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={10}>
          <Card
            style={{
              background: "linear-gradient(90deg, #1CB5E0 0%, #000851 100%)",
            }}
          >
            <Card.Body>
              <section className="status-sec text-center">
                <span>
                  {isSuccess ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="3x"
                      style={{ color: "#fff" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="3x"
                      style={{ color: "#dc3545" }}
                    />
                  )}
                </span>
                <h5 className="text-white py-2">
                  {isSuccess ? "付款成功" : "付款失敗"}
                </h5>
              </section>
            </Card.Body>
          </Card>
          <br />

          <OrderShortCard order={record} />
          {/* <OrderRecord record={record} /> */}
          <br />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button as={Link} to={"/"}>
                回首頁
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                as={Link}
                to={
                  user?._id ? `/user/order` : `/giftRes/result?o=${record._id}`
                }
                variant="outline-primary"
              >
                查看訂單
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
