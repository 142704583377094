import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { renderRoutes } from "react-router-config";

import { useUser } from "../Provider/UserProvider";

const User_page = ({ route }) => {
  const { user, hasToken, redirectAfterLogin } = useUser();

  if (!hasToken) redirectAfterLogin();

  if (!user?._id) {
    return (
      <Row className="justify-content-center">
        <Col xs="auto">
          <Spinner animation="border" />
        </Col>
      </Row>
    );
  }

  return <div>{renderRoutes(route.routes)}</div>;
};

export default User_page;
