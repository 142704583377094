import React, { useState, useEffect } from "react";
import { Nav, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBell, faGift } from "@fortawesome/free-solid-svg-icons";

import "./Footer.css";

const Footer = () => {
  const [unRead, setUnRead] = useState(0);

  return (
    <Nav
      justify
      defaultActiveKey="/"
      className="nav-footer py-1"
    >
      <Nav.Item>
        <Link to="/" className="text-decoration-none">
          <div>
            <FontAwesomeIcon icon={faHome} />
          </div>
          <small>首頁</small>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/user/order" className="text-decoration-none">
          <div>
            <FontAwesomeIcon icon={faGift} />
          </div>
          <small>禮物</small>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link
          to="/user/notification"
          className="text-decoration-none"
        >
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon icon={faBell} />
            {unRead > 0 && (
              <Badge pill variant="danger" className="notification-badge">
                {unRead}
              </Badge>
            )}
          </div>
          <small>通知</small>
        </Link>
      </Nav.Item>
      {/* <Nav.Item className="bg-primary rounded ">
        <Link
          to="/user/notification"
          className="text-decoration-none text-white"
        >
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon icon={faBell} />
          </div>
          <small>送禮須知</small>
        </Link>
      </Nav.Item> */}
    </Nav>
  );
};

export { Footer };
//https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1654943946&redirect_uri=http://127.0.0.1:3000/auth/line/callback&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz
