import { Row, Col, Container } from "react-bootstrap";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logo from "static/logo/logo_en_160x40.svg";

export const ContactFooter = () => {
  return (
    <footer className="bg-dark foot-contact" style={{ marginTop: "auto" }}>
      <Container>
        <Row
          xs={1}
          md={3}
          className="contact-container justify-content-center align-items-center pt-3"
        >
          <Col xs="auto">
            <img src={logo} />
          </Col>
          <Col className="text-center text-white">
          <h6>Skinfarmer Biotech Inc.</h6>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeHHyH3NmrIXkJdKVi0MkMw9I1ScDrIrckHhwG2LIFnBH7Pew/viewform?usp=sf_link"
              target="_blank"
              className="text-white"
            >
              聯繫我們
            </a>
            <h6>
              <FontAwesomeIcon icon={faEnvelope} /> service@nearmespecials.com
            </h6>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
