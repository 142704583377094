import React, { useEffect, useRef, useState } from "react";
import { Carousel, Image, Stack, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

import "./Home.css";
import { SearchProductList, SearchCard } from "Components/Search/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { useIntersectionObserver } from "Hook/useIntersectionObs";
import { GiftNoteBanner } from "Components/Note/Gift";

const Home = () => {
  const recommendWords = [
    { title: "中秋特輯", keyword: "中秋" },
    { title: "父親節特輯", keyword: "父親節" },
    { title: "耳飾", keyword: "耳飾" },
    { title: "項鍊", keyword: "項鍊" },
    { title: "巧克力", keyword: "巧克力" },
    { title: "手錶", keyword: "手錶" },
    { title: "行李箱", keyword: "行李箱" },
    { title: "保健食品", keyword: "人參液" },
  ];

  const HomeRecommend = ({ title, qWord }) => {
    const elementRef = useRef(null);
    const { entry, ob } = useIntersectionObserver(elementRef, {
      rootMargin: "0px 0px 720px 0px",
      freezeOnceVisible: false,
    });

    useEffect(() => {
      if (entry && entry.isIntersecting) ob.disconnect();
    }, [entry]);

    return (
      <section ref={elementRef} style={{ minHeight: "360px" }}>
        <h5>
          <Link className="text-decoration-none text-dark" to={`/search?q=${qWord}`}>
            {title}
          </Link>
          <FontAwesomeIcon icon={faGift} className="text-primary" />
        </h5>
        {entry?.isIntersecting && (
          <SearchProductList
            qText={qWord}
            horizontal
            morePage={false}
            pFilter={false}
          />
        )}
      </section>
    );
  };

  return (
    <div>
      {/* Banner */}
      {/* Desktop Banner */}
      {/* <Carousel className="home-banner-desktop py-2">
        {[
          {
            link: "https://blog.nearmespecials.com/tutorial-gift",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01-pc.png",
          },
          {
            link: "https://blog.nearmespecials.com/giftbiz/",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02-pc.png",
          },
        ].map((e) => (
          <Carousel.Item key={e.image}>
            <Image
              src={e.image}
              onClick={() => {
                if (e.link) window.open(e.link);
              }}
              alt="NearMe"
              key={e.image}
              className="home-banner-item"
              style={{ maxHeight: "50vh", width: "100vw", objectFit: "cover" }}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      <Carousel className="home-banner-mobile py-2">
        {[
          {
            link: "https://blog.nearmespecials.com/tutorial-gift",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01-mobile.png",
          },
          {
            link: "https://blog.nearmespecials.com/giftbiz/",
            image:
              "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02-mobile.png",
          },
        ].map((e) => (
          <Carousel.Item key={e.image}>
            <Image
              src={e.image}
              onClick={() => {
                if (e.link) window.open(e.link);
              }}
              alt="NearMe"
              key={e.image}
              className="home-banner-item"
              style={{ maxHeight: "50vh", width: "100vw", objectFit: "cover" }}
            />
          </Carousel.Item>
        ))}
      </Carousel> */}
      <Stack className="col-md-8 mx-auto">
        <Carousel
          indicators={false}
          prevIcon={
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="text-dark bg-white"
              style={{ borderRadius: "50%" }}
              size="2x"
            />
          }
          nextIcon={
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="text-dark bg-white"
              style={{ borderRadius: "50%" }}
              size="2x"
            />
          }
        >
          {[
            {
              link: "https://blog.nearmespecials.com",
              image:
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner01.png",
            },
            {
              link: "https://blog.nearmespecials.com",
              image:
                // "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/receive-gift-tut.png",
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner02.png",
            },
            {
              link: "https://blog.nearmespecials.com",
              image:
                "https://nearme-stores.s3.ap-northeast-1.amazonaws.com/official/banner/client-home-banner03.png",
            },
          ].map((e) => {
            return (
              <Carousel.Item key={e.image}>
                <Image
                  src={e.image}
                  onClick={() => {
                    if (e.link) window.open(e.link);
                  }}
                  alt="NearMe"
                  key={e.image}
                  width={"100%"}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Stack>
      <br />

      <Stack className="text-center d-none" gap={2}>
        <h2
          style={{
            fontSize: "3.75rem",
          }}
        >
          <strong className="search-text-gradient">NearMe</strong>
        </h2>
        {/* <h2 style={{ fontWeight: 700 }}>買錯禮物？？別擔心</h2> */}
        <h2 style={{ fontWeight: 700 }} className="search-text-gradient">
          各大電商商品，<span className="text-primary">輕鬆換</span>
        </h2>
        {/* <strong>
          搜尋或貼上商品連結(https://www.pinkoi.com/product/WuUPB5Fz)
        </strong> */}

        <Link to="/FAQ" className="mx-auto">
          <Button size="sm" variant="outline-dark">
            <strong>深入了解 {">"}</strong>
          </Button>
        </Link>
      </Stack>
      <br />
      <hr />
      {recommendWords.map((e, idx) => (
        <section key={e.title}>
          <HomeRecommend title={e.title} qWord={e.keyword} />
          {idx === 4 && (
            <Stack className="my-5 col-md-8 mx-auto">
              <SearchCard />
            </Stack>
          )}

          {idx === 1 && (
            <Stack className="my-5 col-md-8 mx-auto">
              <GiftNoteBanner />
            </Stack>
          )}
        </section>
      ))}
    </div>
  );
};

export { Home };
