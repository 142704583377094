export const useECPay = () => {
  const insertHTMLAndSubmit = (code) => {
    let ecpayForm = document.getElementById("ecpay_form");

    if (!ecpayForm) {
      document.body.insertAdjacentHTML(
        "beforeend",
        `<div id="ecpay_form"></div>`
      );
      ecpayForm = document.getElementById("ecpay_form");
      if (!ecpayForm) throw new Error("結帳系統錯誤(ecpay-001)");
    }

    ecpayForm.insertAdjacentHTML("afterbegin", code);

    let scriptEle = document.getElementById("_form_aiochk");

    if (!scriptEle) throw new Error("結帳系統錯誤(ecpay-002)");

    // scriptEle.submit();

    return new Promise((resolve, reject) => {
      const submitHandler = (event) => {
        // Cleanup event listeners
        scriptEle.removeEventListener('submit', submitHandler);
        scriptEle.removeEventListener('error', errorHandler);

        // Resolve the promise as submission is successful
        resolve();
      };

      const errorHandler = (event) => {
        // Cleanup event listeners
        scriptEle.removeEventListener('submit', submitHandler);
        scriptEle.removeEventListener('error', errorHandler);

        // Reject the promise if there's an error during submission
        reject(event);
      };

      // Add event listeners
      scriptEle.addEventListener('submit', submitHandler);
      scriptEle.addEventListener('error', errorHandler);

      // Trigger the form submission
      scriptEle.submit();
    });
  };
  return { insertHTMLAndSubmit };
};
