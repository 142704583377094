import { Home } from "../User/Home/Home_page";
import { Classification } from "../User/Classification/Classification_page";
import User_page from "../User/User_page";
import { Login } from "../Login/Login";
import { LoginOptions } from "../Login/LoginForm/LoginForm";
import { RegisterForm, RegisterCallback } from "../Login/Register/RegisterForm";
import {
  ForgetPassword,
  ForgetPasswordCallback,
} from "../Login/ForgetPassword/ForgetPassword";
import { PrivacyPolicyPage } from "../Login/Policy/PrivacyPolicy";
import { ServicePolicy } from "../Login/Policy/ServicePolicy";
import { Register_page } from "../User/Register_page";
import { SearchPage, SearchProductPage } from "../User/Search/SearchPage";
import { Registration_Form } from "../User/User_info/Registration_Form_Page";
import { CustomerServiceEmail, Customer_Service } from "../User/User_info/CustoemrService/Customer_Service";
import { RegisterStore } from "../User/RegisterStore/RegisterStore";
import {
  BasicForm,
  InfoForm,
  CertificateForm,
  BuyBuildService,
  Contract,
} from "../User/RegisterStore/RegisterBasicForm";
import { RequestLocationPage } from "../Location/RequestLocationPage";
import { ShopPaymentPage } from "../User/Payment/ShopPaymentPage";
import {
  PaymentPage,
  ScrapeProductPaymentPage,
} from "../User/Payment/PaymentPage";
import {
  GiftMarkUpPaymentResult,
  PaymentResultPage,
} from "../User/Payment/PaymentResult";
import { IPAreaPage } from "../User/IPArea/IPAreaPage";
import { IndexPage } from "../Root/Root";

import { routes as userRoutes } from "../User/router/userRouters";

import { TemporaryFixRoute } from "./TemporaryFixRoute";
import { NotFound } from "./NotFound";
import { UserMenuMobile } from "User/User_info/Menu/UserInfoHome";
import { GiftResPage, GiftResult } from "User/Gift/GiftPage";
import {FAQ} from "Components/FAQ/FAQ"

const routes = [
  {
    path: "/",
    component: IndexPage,
    routes: [
      {
        path: "/",
        component: Home,
        breadcrumbName: "Home",
        exact: true,
      },
      {
        
        path: "/FAQ",
        component: FAQ,
        breadcrumbName: "FAQ",
        exact: true,
      },
      {
        path: "/menu",
        component: UserMenuMobile,
        exact: true,
      },
      {
        path: "/user",
        component: User_page,
        routes: userRoutes,
      },
      {
        path: "/giftRes",
        component: GiftResPage,
        exact: true,
      },
      {
        path: "/giftRes/result",
        component: GiftResult,
        exact: true,
      },
      {
        path: "/gift/res/:id",
        component: GiftResPage,
        exact: true,
      },
      {
        path: "/classification",
        component: Classification,
        breadcrumbName: "Classification",
        exact: true,
      },
      {
        path: "/ipArea",
        component: IPAreaPage,
        exact: true,
        breadcrumbName: "商圈",
      },
      { path: "/parameter/:sharename/:aceeptname", component: Register_page },
      {
        path: "/search",
        component: SearchProductPage,
        exact: true,
        breadcrumbName: "ShopListPage",
      },
      {
        path: "/registerStore",
        component: RegisterStore,
        breadcrumbName: "registerStore",
      },
      {
        path: "/requestLocation",
        component: RequestLocationPage,
        breadcrumbName: "requestLocation",
      },
      {
        path: "/customerService",
        component: CustomerServiceEmail,
        breadcrumbName: "Customer Service",
        
      },
      // {
      //   path: "/payment/request/:shopID",
      //   component: ShopPaymentPage,
      //   breadcrumbName: "payment",
      // },
      // {
      //   path: "/payment/order/request",
      //   component: PaymentPage,
      //   breadcrumbName: "payment",
      // },
      // {
      //   path: "/payment/scrapeOrder/request",
      //   component: ScrapeProductPaymentPage,
      //   breadcrumbName: "payment",
      // },
      // {
      //   path: "/payment/result/order/:paymentID",
      //   component: PaymentResultPage,
      //   breadcrumbName: "PaymentResult",
      // },
      {
        path: "/payment/result/markup/:paymentID",
        component: GiftMarkUpPaymentResult,
        breadcrumbName: "PaymentResult",
      },
      {
        path: "/login",
        component: Login,
        breadcrumbName: "登入",
        routes: [
          {
            path: "/login",
            component: LoginOptions,
            breadcrumbName: "登入",
            exact: true,
          },
          {
            path: "/login/register",
            component: RegisterForm,
            breadcrumbName: "註冊",
            exact: true,
          },
          {
            path: "/login/registerCallback",
            component: RegisterCallback,
            breadcrumbName: "註冊驗證",
            exact: true,
          },
          {
            path: "/login/contract/privacyPolicy",
            component: PrivacyPolicyPage,
            breadcrumbName: "隱私政策",
            exact: true,
          },
          {
            path: "/login/forgetpassword",
            component: ForgetPassword,
            breadcrumbName: "忘記密碼",
            exact: true,
          },
          {
            path: "/login/forgetpassword/callback",
            component: ForgetPasswordCallback,
            breadcrumbName: "忘記密碼驗證",
            exact: true,
          },
        ],
      },

      {
        path: "/BasicForm",
        component: BasicForm,
        routes: [
          {
            path: "/BasicForm/Home",
            component: RegisterStore,
            breadcrumbName: "申請首頁",
          },
          {
            path: "/BasicForm/InfoForm/:storeType",
            component: InfoForm,
            breadcrumbName: "申請",
          },
          {
            path: "/BasicForm/CertificateForm",
            component: CertificateForm,
            breadcrumbName: "上傳證件",
          },
          {
            path: "/BasicForm/BuyBuildService",
            component: BuyBuildService,
            breadcrumbName: "購買建置服務",
          },
          {
            path: "/BasicForm/Contract",
            component: Contract,
            breadcrumbName: "簽署合約",
          },
        ],
      },
      {
        path: "/index",
        component: TemporaryFixRoute,
      },
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
  // {
  //   path: "/login",
  //   component: Login,
  //   breadcrumbName: "登入",
  //   routes: [
  //     {
  //       path: "/login",
  //       component: LoginOptions,
  //       breadcrumbName: "登入",
  //       exact: true,
  //     },
  //     {
  //       path: "/login/register",
  //       component: RegisterForm,
  //       breadcrumbName: "註冊",
  //       exact: true,
  //     },
  //     {
  //       path: "/login/registerCallback",
  //       component: RegisterCallback,
  //       breadcrumbName: "註冊驗證",
  //       exact: true,
  //     },
  //     {
  //       path: "/login/contract/privacyPolicy",
  //       component: PrivacyPolicyPage,
  //       breadcrumbName: "隱私政策",
  //       exact: true,
  //     },
  //     {
  //       path: "/login/contract/servicePolicy",
  //       component: ServicePolicy,
  //       breadcrumbName: "服務政策",
  //       exact: true,
  //     },
  //     {
  //       path: "/login/forgetpassword",
  //       component: ForgetPassword,
  //       breadcrumbName: "忘記密碼",
  //       exact: true,
  //     },
  //     {
  //       path: "/login/forgetpassword/callback",
  //       component: ForgetPasswordCallback,
  //       breadcrumbName: "忘記密碼驗證",
  //       exact: true,
  //     },
  //   ],
  // },

  // {
  //   path: "/BasicForm",
  //   component: BasicForm,
  //   routes: [
  //     {
  //       path: "/BasicForm/Home",
  //       component: RegisterStore,
  //       breadcrumbName: "申請首頁",
  //     },
  //     {
  //       path: "/BasicForm/InfoForm/:storeType",
  //       component: InfoForm,
  //       breadcrumbName: "申請",
  //     },
  //     {
  //       path: "/BasicForm/CertificateForm",
  //       component: CertificateForm,
  //       breadcrumbName: "上傳證件",
  //     },
  //     {
  //       path: "/BasicForm/BuyBuildService",
  //       component: BuyBuildService,
  //       breadcrumbName: "購買建置服務",
  //     },
  //     {
  //       path: "/BasicForm/Contract",
  //       component: Contract,
  //       breadcrumbName: "簽署合約",
  //     },
  //   ],
  // },
];

export default routes;
