import { GiftTutorial } from "Components/Tutorial/Gift";
import { Alert, Accordion, Modal } from "react-bootstrap";

export const FAQModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>FAQ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FAQ />
      </Modal.Body>
    </Modal>
  );
};

export const FAQ = () => {
  return (
    <div>
      <GiftTutorial />
      <Alert>
        <Alert.Heading>注意事項</Alert.Heading>
        <hr />
        <p>-如果收禮人10天內未選擇收下或更換，則禮物連結將自動失效(不扣款).</p>
        <p>-如果更換金額較高的禮物，則由收禮人刷卡補差額.</p>
        <p>-完成收件資訊後才啟動扣款，及發送電子發票.</p>
      </Alert>

      <Accordion defaultActiveKey={"0"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>送禮為何透過NearMe代購?</Accordion.Header>
          <Accordion.Body>
            <p>
              使收禮人一起參與禮物的決定！
              如果你的原始禮物不合收禮人的心意，可以允許他自行更換禮物
              {/* <strong className="text-primary">兌換</strong> */}
            </p>
            <strong className="text-primary">
              更換禮物不限於原本的平台，可以自由更換各大電商平台的商品
            </strong>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>更換禮物差額如何處理?</Accordion.Header>
          <Accordion.Body>
            <p>1-超出原始禮物金額的差額，由收禮人另外刷卡支付</p>
            <p className="text-primary">
              2-低於原始禮物金額，將退刷差額返還送禮人
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>如何送禮?</Accordion.Header>
          <Accordion.Body>
            <p>1-選擇商品:</p>
            <p>1.1：在NearMe搜尋商品或</p>
            <p>
              1.2：至NearMe支援的電商選擇商品，再將商品網址複製到NearMe搜尋框
            </p>
            <p>2-預付款: 刷信用卡預授權，取得禮物連結</p>
            <p className="text-primary">
              3-傳送連結:複製禮物連結，傳送給收禮人
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>如何收禮?</Accordion.Header>
          <Accordion.Body>
            <p>1-收禮人擇收下或更換: 打開禮物連結後，選擇收下或更換.</p>
            <p>2-收禮人填寫收件資訊</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
