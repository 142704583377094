import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const LoadingPage = ({ title }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center bg-light sticky-top"
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        opacity: "0.8",
        top: 0,
        left: 0,
      }}
    >
      <h4 className="text-muted">{title}</h4>
      <Spinner animation="grow" />
    </div>
  );
};

export const LoadingSection = () => {
  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <Spinner animation="border" />
      </Col>
    </Row>
  );
};
