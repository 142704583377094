import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  useMemo,
} from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Http_request_get, Http_request_post } from "../Service/HttpService";
import { APIUserInfo, APILogout, APIFetchOwnStores } from "../API/user";
import { LoadingPage } from "../Components/LoaingPage";
// import * as firebaseUtils from "../Firebase";
import { APIFetchOwnOrder, APIFetchOwnScrapeOrder } from "API/order";

const UserContext = React.createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState();
  const [ownOrder, setOwnOrder] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const hasToken = document.cookie.match(/userToken=/);

  useEffect(() => {
    // const token = localStorage.getItem("token");

    if (!isLogin && hasToken) fetchUser();
  }, [location]);

  // Fetch own coupon records.
  useEffect(() => {
    if (!isLogin) return;
    fetchOrder()
      .then((res) => {
        res = res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setOwnOrder(res);
      })
      .catch((err) => console.log("fetch own coupon faill"));
  }, [user]);

  //update web push token
  // useEffect(() => {
  //   if (!user?._id || !user?.webPush?.token) return;

  //   if (passDay(user.webPush.date) < 7 || !user.webPush.date) return;

  //   try {
  //     if (Notification.permission !== "granted") return;

  //     getWebPushToken();
  //   } catch (_) {}
  // }, [user]);

  const fetchUser = async () => {
    // const userToken = localStorage.getItem("token");

    if (!hasToken) {
      setIsLogin(false);
      return;
    }

    const result = await Http_request_get(APIUserInfo).catch((err) => {
      redirectAfterLogin();
    });

    if (!result.data) return;

    setUser(result.data);

    setIsLogin(true);
  };

  const fetchOrder = async () => {
    let result = await Http_request_get(APIFetchOwnScrapeOrder);
    setOwnOrder(result.data);
    return result.data;
  };

  const handleLogout = async (redirect = "/") => {
    Http_request_post(APILogout);

    localStorage.removeItem("token");

    setIsLogin(false);

    document.cookie =
      "userToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    history.push(redirect);
  };

  const fetchOwnStores = async () => {
    let result = await Http_request_get(APIFetchOwnStores);

    if (!result.data) throw new Error(result.message);

    return result.data;
  };

  const getWebPushToken = async () => {
    // let webPushToken = await firebaseUtils.getWebPushToken();
    // // let webPushToken = '';
    // if (!webPushToken) throw new Error("無法取得通知憑證");
    // let result = await Http_request_post("user/webPush/add", { webPushToken });
    // if (result.data) setUser(result.data);
  };

  const redirectAfterLogin = () => {
    if (location.pathname === "/login") return;

    let redirectPath = encodeURIComponent(
      `${location.pathname}${location.search}`
    );

    history.push(`/login?redirect=${redirectPath}`);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        fetchUser,
        handleLogout,
        isLogin,
        getWebPushToken,
        ownOrder,
        setOwnOrder,
        fetchOrder,
        hasToken,
        fetchOwnStores,
        redirectAfterLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const LoadingContext = React.createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {loading ? <LoadingPage /> : ""}
      {children}
    </LoadingContext.Provider>
  );
};
